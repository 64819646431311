
.news-tbanner {
    display: flex;
    justify-content: center;
    align-items: center;

    color: #fff;
    height: 60px;
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
    background: url('/img/common/dot.png');
    background-size: 5%;
    &.pharmacy {
        background: url('/img/common/dot_green.png');
    }
    @include md {
        height: 180px;
        font-size: 4.6rem;
        background-size: auto;
    }
}

.news-list {
    margin: 50px auto;
    padding: 0 20px;
    width: 100%;
    max-width: 1000px;
    @include md {
        margin: 100px auto;
    }
    .wrapper {
        text-align: center;
    }
    ul {
        display: inline-block;
        text-align: left;
        li {
            display: flex;
            margin: 30px 0;
            padding: 20px 10px;
            font-size: 1.6rem;
            border-bottom: 1px solid #4C4948;
            .date {
                margin-right: 20px;
            }
            a {
                color: #4C4948;
            }
        }
    }
}

.news-detail {
    margin: 50px auto;
    padding: 0 20px;
    width: 100%;
    max-width: 1000px;

    font-size: 1.6rem;
    @include md {
        margin: 100px auto;
    }
    .date {
        margin-bottom: 20px;
    }
    .title {
        margin-bottom: 20px;
        padding-bottom: 10px;
        font-weight: 700;
        border-bottom: 1px solid #000;
    }
    .body {
        white-space: pre-wrap;
    }
}