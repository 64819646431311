
@import '_variables.scss';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
    &:hover {
        opacity: .8;
    }
}

ul {
    list-style: none;
}

img {
    vertical-align: middle;
}

html, body {
    text-size-adjust: 100%;
    font-size: $base-font-size;
    height: 100%;
    font-family: $font-family;
}

pre {
    font-family: $font-family;
}

#main {
    display: block;
    padding-top: $header-height-sp;
    width: 100%;
    @include md {
        padding-top: $header-height;
    }
}
#content {
}

.pc-only { @include pc-only; }
.sp-only { @include sp-only; }

.text-center {
    text-align: center;
}

[hidden] {
    display: none;
}

[v-cloak] {
    display: none !important;
}

[data-animation] {
    opacity: 0;
    transform: translateY(50px);
    transition: all 1s ease-out;
}

.fade-in {
    opacity: 1;
    transform: none;
    transition: all 1s ease-out;
}

@import 'header';
@import 'footer';

@import 'home/all';
@import 'drug/all';
@import 'pharmacy/all';
@import 'career/all';
@import 'entry/all';
@import 'welfare/all';

@import 'person/all';
@import 'news/all';

@import 'message/all';
@import 'outline/all';
@import 'concept/all';