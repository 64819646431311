
.drug-banner {
    img {
        width: 100%;
    }
}


.drug-text {
    margin-top: 50px;
    text-align: center;
    @include md {
        margin-top: 0;
    }
    img {
        width: 80%;
        @include md {
            width: 100%;
        }
    }
}


.drug-pamphlet {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    margin-top: 50px;
    padding: 50px 0;
    background-color: $color-red;
    @include md {
        margin-top: 100px;
        flex-direction: row;
        > div {
            width: 50%;
        }
    }
    .left {
        text-align: center;
        .wrapper {
            display: inline-block;
            color: #fff;
            text-align: left;
            @include md {
                display: block;
                margin-left: auto;
                width: 300px;
            }
            h2 {
                margin-top: 20px;
                font-size: 2.2rem;
                text-align: center;
                @include md {
                    margin-top: 0;
                    font-size: 2.6rem;
                    text-align: left;
                }
            }
            p {
                margin-top: 10px;
                font-size: 1.4rem;
                line-height: 2;
                @include md {
                    margin-top: 30px;
                    font-size: 1.5rem;
                }
            }
            a {
                position: relative;

                display: flex;
                justify-content: center;
                align-items: center;

                margin-top: 50px;
                color: #fff;
                width: 250px;
                height: 50px;
                font-size: 1.3rem;
                background-color: #4C4948;
                border: 1px solid #fff;
                &::after {
                    position: absolute;
                    content: "";
                    right: .5em;
                    top: 50%;
                    display: block;
                    border-right: 2px solid #fff;
                    border-bottom: 2px solid #fff;
                    width: 8px;
                    height: 8px;
                    transform: translate(-50%,-50%) rotate( -45deg );
                }
            }
        }
    }
    .right {
        text-align: center;
        @include md {
            padding-left: 50px;
            text-align: left;
        }
        img {
            width: 100%;
            max-width: 220px;
        }
    }
}

.drug-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-top: 30px;
    margin-bottom: 30px;
    @include md {
        flex-direction: row;
        margin-top: 100px;
        margin-bottom: 100px;
    }
    a {
        display: block;
        margin: 10px 20px;
        width: 90%;
        max-width: 500px;
        overflow: hidden;
        @include md {
            margin: 0 20px;
            width: 40%;
        }
    }
    img {
        width: 100%;
        transition: all .5s;
        @include md {
            &:hover {
                transform: scale(1.05);
            }
        }
    }
}



.drug-people {

    margin-top: 50px;
    @include md {
        margin-top: 100px;
    }

    .stitle {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        margin-bottom: 50px;
        .en {
            margin-bottom: 10px;
            font-size: 1.5rem;
            @include md {
                margin-bottom: 20px;
            }
        }
        .ja {
            font-size: 2.2rem;
            letter-spacing: .2em;
        }
    }

    .swiper-slide {
        width: 300px;
    }
    
    .slider {
        padding: 50px 0;
        background-image: url('/img/drug/bg_01.png');
        .mySwiper {
            a {
                position: relative;
                display: block;
            }
            span {
                position: absolute;
                left: 0;
                z-index: 1;
                bottom: 100px;
                padding: .5em 2em;
                color: #fff;
                font-size: 1.2rem;
                background-color: #4C4948;
            }
            .image {
                width: 100%;
                overflow: hidden;
            }
            img {
                max-width: 100%;
                transition: all .5s;
                @include md {
                    &:hover {
                        transform: scale(1.05);
                    }
                }
            }
            p {
                display: flex;
                align-items: center;
                padding: 0 20px;
                height: 100px;
                color: #4C4948;
                font-size: 1.4rem;
                background-color: #fff;
            }
        }
        .list {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            margin: 30px auto;
            color: #fff;
            width: 250px;
            height: 60px;
            font-size: 1.3rem;
            background-color: #4C4948;
            border: 1px solid #fff;

            &::after {
                position: absolute;
                content: "";
                right: .5em;
                top: 50%;
                display: block;
                border-right: 2px solid #fff;
                border-bottom: 2px solid #fff;
                width: 8px;
                height: 8px;
                transform: translate(-50%,-50%) rotate( -45deg );
            }
        }
    }
}



.drug-entry {

    margin: 50px 0;
    @include md {
        margin: 100px 0;
    }
    a {
        display: flex;
        justify-content: center;
        align-items: center;

        margin: auto;
        width: 60%;
        max-width: 500px;
    }
    img {
        max-width: 100%;
    }
}




.drug-movie-tbanner {
    display: flex;
    justify-content: center;
    align-items: center;

    color: #fff;
    height: 60px;
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
    background: url('/img/common/dot.png');
    background-size: 5%;
    @include md {
        height: 180px;
        font-size: 4.6rem;
        background-size: auto;
    }
}


.drug-movie-description {
    margin: 50px auto;
    padding: 0 20px;
    max-width: 900px;
    text-align: center;
    @include md {
        margin: 100px auto;
    }
    h2 {
        color: $color-red;
        font-size: 1.8rem;
        line-height: 1.8;
        @include md {
            font-size: 3.2rem;
        }
    }
    p {
        margin-top: 20px;
        font-size: 1.5em;
        line-height: 2;
        @include md {
            margin-top: 50px;
            font-size: 1.7rem;
        }
    }
}

.drug-movie-video {
    margin: 50px auto;
    padding: 0 20px;
    max-width: 900px;
    .wrapper {
        position: relative;
        &::after {
            content: '';
            display: block;
            width: 100%;
            padding-top: calc( 9 / 16 * 100% );
        }
    }
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    p {
        margin-top: 10px;
        font-size: 1.4rem;
    }
}




.drug-recruit-tbanner {
    display: flex;
    justify-content: center;
    align-items: center;

    color: #fff;
    height: 60px;
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
    background: url('/img/common/dot.png');
    background-size: 5%;
    @include md {
        height: 180px;
        font-size: 4.6rem;
        background-size: auto;
    }
}

.drug-recruit-detail {
    margin: 50px auto;
    padding: 0 20px;
    max-width: 900px;
    @include md {
        margin: 100px auto;
    }
    table {
        width: 100%;
        font-size: 1.6rem;
        border-collapse: collapse;
        @include md {
            font-size: 1.8rem;
        }
        th, td {
            display: block;
            padding: .75em 1em;
            width: 100%;
            text-align: left;
            border-bottom: 1px solid #000;
            line-height: 2;
            @include md {
                display: table-cell;
                width: auto;
                vertical-align: top;
            }
        }
        th {
            @include md {
                width: 12em;
            }
        }
    }
    a {
        color: #f08300;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    > p {
        margin-top: 20px;
        font-size: 1.8rem;
        text-align: right;
    }
}