
.entry-tbanner {
    display: flex;
    justify-content: center;
    align-items: center;

    color: #fff;
    height: 60px;
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
    background: url('/img/common/dot.png');
    background-size: 5%;
    @include md {
        height: 180px;
        font-size: 4.6rem;
        background-size: auto;
    }
}

.entry-block {
    padding: 0 20px;
}

.entry-intro,
.entry-internship {
    margin: 100px auto;
    padding: 0 20px 20px;
    max-width: 1000px;
    @include md {
        margin: 150px auto 200px;
        border: 4px solid $color-red;
    }
    h2 {
        display: flex;
        justify-content: center;
        align-items: center;

        margin: auto;
        color: #fff;
        width: 260px;
        height: 50px;
        font-size: 1.8rem;
        background-color: $color-red;

        transform: translateY(calc(-50% + 10px));
        @include md {
            width: 430px;
            height: 75px;
            font-size: 2.8rem;

            transform: translateY(calc(-50% - 2px));
        }
    }
    p {
        font-size: 1.8rem;
        font-weight: 700;
        text-align: center;
        @include md {
            font-size: 2.5rem;
        }
    }
    .arrow {
        margin: 10px 0;
        text-align: center;
        @include md {
            margin: 20px 0;
        }
        img {
            width: 15px;
            @include md {
                width: 20px;
            }
        }
    }
    ul {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        margin: auto;
        margin-top: 20px;
        width: 100%;
        @include md {
            flex-direction: row;
            margin-top: 40px;
            max-width: 640px;
        }
        li {
            flex: 0 0 auto;
            margin: 10px 0;
            width: 80%;
            max-width: 300px;
            @include md {
                margin-bottom: 10px;
                width: 60%;
            }
            img {
                max-width: 100%;
            }
        }
    }
}