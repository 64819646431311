
.concept-tbanner {
    display: flex;
    justify-content: center;
    align-items: center;

    color: #fff;
    height: 60px;
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
    background: url('/img/common/dot.png');
    background-size: 5%;
    @include md {
        height: 180px;
        font-size: 4.6rem;
        background-size: auto;
    }
}


.concept-copy {
    margin: 50px 0;
    font-size: 2.2rem;
    font-weight: 700;
    text-align: center;
    @include md {
        margin: 100px 0;
        font-size: 4.3rem;
    }
}


.concept-detail {
    padding: 0 20px;
    ul {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: auto;
        max-width: 1200px;
        @include md {
            flex-direction: row;
        }
        li {
            flex: 0 0 auto;
            margin: auto;
            margin-bottom: 50px;
            width: 170px;
            @include md {
                margin: 0;
                width: 30%;
                max-width: 290px;
            }
            img {
                width: 100%;
            }
        }
    }
}


.concept-origin {

    margin: auto;
    margin-top: 50px;
    padding: 0 20px;
    width: 100%;
    max-width: 1400px;
    background-image: url('/img/concept/bg_01.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom;
    @include md {
        margin-top: 100px;
    }

    .stitle {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        margin-bottom: 20px;
        color: $color-red;
        text-align: center;
        @include md {
            margin-bottom: 50px;
        }
        .en {
            margin-bottom: 10px;
            font-size: 1.3rem;
            @include md {
                margin-bottom: 20px;
                font-size: 1.5rem;
            }
        }
        .ja {
            font-size: 1.8rem;
            letter-spacing: .2em;
            @include md {
                font-size: 2.2rem;
            }
        }
    }

    .wrapper {
        display: flex;
        flex-direction: column;

        margin: auto;
        padding-bottom: 40px;
        width: 100%;
        max-width: 850px;
        @include md {
            flex-direction: row;
            padding: 0 50px 100px;
        }
        .left {
            flex: 0 0 auto;
            margin: auto;
            margin-bottom: 20px;
            width: 80px;
            @include md {
                margin: 0;
                margin-right: 50px;
                width: 250px;
            }
            img {
                width: 100%;
            }
        }
        .right {
            max-width: 580px;
            p {
                font-size: 1.4rem;
                line-height: 2.4;
                letter-spacing: 1px;
                text-align: justify;
                @include md {
                    font-size: 1.6rem;
                }
            }
        }
    }
}