
.message-tbanner {
    display: flex;
    justify-content: center;
    align-items: center;

    color: #fff;
    height: 60px;
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
    background: url('/img/common/dot.png');
    background-size: 5%;
    @include md {
        height: 180px;
        font-size: 4.6rem;
        background-size: auto;
    }
}


.message-banner {
    @include md {
        transform: translateY(-12%);
    }
    img {
        width: 100%;
    }
}


.message-text {
    margin: 50px auto;
    padding: 0 20px;
    width: 100%;
    max-width: 900px;
    font-size: 1.7rem;
    line-height: 2.5;
    letter-spacing: 1px;
    @include md {
        margin: 50px auto 100px;
    }
    h3 {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;

        margin-top: 20px;
        font-weight: 400;
        @include md {
            margin-top: 50px;
        }
        span {
            display: block;
            line-height: 1;
            &:first-child  {
                padding-top: 5px;
                margin-right: 20px;
                font-size: 1.8rem;
            }
            &:last-child  {
                font-size: 3.2rem;
                font-weight: 700;
            }
        }
    }
    .image {
        margin-top: 40px;
        width: 100%;
        @include md {
            margin-top: -50px;
            width: 40%;
        }
        img {
            max-width: 100%;
        }
    }
}