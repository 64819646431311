
.person-tbanner {
    display: flex;
    justify-content: center;
    align-items: center;

    color: #fff;
    height: 60px;
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
    background: url('/img/common/dot.png');
    background-size: 5%;
    &.pharmacy {
        background: url('/img/common/dot_green.png');
    }
    @include md {
        height: 180px;
        font-size: 4.6rem;
        background-size: auto;
    }
}

.person-info {
    display: flex;
    flex-direction: column-reverse;
    margin: auto;
    max-width: 1400px;
    @include md {
        flex-direction: row;
    }
    > div {
        flex: 0 0 auto;
        width: 100%;
        @include md {
            width: 50%;
        }
    }
    .left {
        display: flex;
        justify-content: center;
        align-items: center;

        padding: 20px 20px 30px;
        background-color: #4C4948;
        @include md {
            padding: 20px;
        }
        .wrapper {
            max-width: 400px;
            div {
                display: inline-flex;
                flex-direction: column;
            }
        }
        h4 {
            color: #fff;
            font-size: 2rem;
            line-height: 1.8;
            letter-spacing: 1px;
            @include md {
                font-size: 2.5rem;
            }
        }
        p {
            display: block;
            margin: 1em 0 .5em;
            padding: .5em 1em;
            color: #fff;
            font-size: 1.2rem;
            text-align: center;
            border: 1px solid #fff;
        }
        h5 {
            display: block;
            padding: .25em 1em;
            font-size: 1.8rem;
            text-align: center;
            background-color: #fff;
            @include md {
                font-size: 2.4rem;
            }
        }
    }
    .right {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
}

.person-movie {
    margin: 50px auto;
    padding: 0 20px;
    max-width: 900px;
    &.pharmacy {
        h3 {
            color: $color-green;
        }
    }
    h3 {
        margin-bottom: 20px;
        color: $color-red;
        text-align: center;
        font-size: 1.6rem;
        @include md {
            font-size: 1.8rem;
        }
    }
    .wrapper {
        position: relative;
        &::after {
            content: '';
            display: block;
            width: 100%;
            padding-top: calc( 9 / 16 * 100% );
        }
    }
    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    p {
        margin-top: 10px;
        font-size: 1.4rem;
    }
}

.person-interview {
    margin: auto;
    width: 100%;
    max-width: 1400px;
    &-detail {
        margin: 50px 0;
        &.pharmacy {
            .wrapper {
                p {
                    color: $color-green;
                }
                h3 {
                    background-color: $color-green;
                }
            }
        }
        .wrapper {
            margin: auto;
            padding: 0 20px;
            max-width: 900px;
            p {
                margin-bottom: 10px;
                color: $color-red;
                font-size: 1.6rem;
                @include md {
                    font-size: 1.8rem;
                }
            }
            h3 {
                display: inline-block;
                padding: .25em 1em;
                color: #fff;
                font-size: 1.6rem;
                font-weight: 400;
                background-color: $color-red;
                line-height: 1.6;
                @include md {
                    font-size: 2.5rem;
                }
            }
            pre {
                margin-top: 20px;
                font-size: 1.6rem;
                line-height: 1.8;
                white-space: pre-wrap;
            }
        }
        .image {
            margin-top: 40px;
            width: 100%;
            max-width: 900px;
            img {
                width: 100%;
            }
        }
        &:nth-child(1) {
            .image {
                margin-left: auto;
            }
        }
    }
}



.people-tbanner {
    display: flex;
    justify-content: center;
    align-items: center;

    color: #fff;
    height: 60px;
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
    background: url('/img/common/dot.png');
    background-size: 5%;
    &.pharmacy {
        background: url('/img/common/dot_green.png');
    }
    @include md {
        height: 180px;
        font-size: 4.6rem;
        background-size: auto;
    }
}


.people-list {
    margin: 50px auto;
    max-width: 1200px;
    @include md {
        margin: 100px auto;
    }
    ul {
        display: flex;
        flex-wrap: wrap;
        padding: 0 20px;
        li {
            margin-bottom: 20px;
            width: 100%;
            @include md {
                width: calc(50% - 10px);
                &:nth-child(odd) {
                    margin-right: 20px;
                }
            }
            a {
                position: relative;
                display: block;
            }
            .info {
                position: absolute;
                left: 0;
                bottom: 0;

                padding: 10px 20px;
                color: #fff;
                width: 100%;
                font-size: 1.6rem;
                background-color: rgba($color: #000000, $alpha: .5);
            }
            img {
                width: 100%;
            }
        }
    }
}