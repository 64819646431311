

.home-banner {
    position: relative;
    img {
        max-width: 100%;
    }
    .text {
        position: absolute;
        left: 50%;
        top: 35%;
        width: 76%;
        transform: translate(-50%, -50%);
        transition: all .5s ease-in-out;
        transition-delay: .5s;
        @include md {
            top: 50%;
            width: 32%;
        }
        &.scale {
            transform: translate(-50%, -50%) scale(.8);
            opacity: 0;
        }
    }
}

.home-link {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    margin: auto;
    margin-top: 20px;
    padding: 0 20px;
    max-width: 1100px;
    @include md {
        flex-direction: row;
        margin-top: 100px;
    }
    > div {
        width: 100%;
        max-width: 600px;
        overflow: hidden;
        @include md {
            width: calc(50% - 5px);
        }
        &:first-child {
            margin-bottom: 10px;
            @include md {
                margin-bottom: 0;
                margin-right: 10px;
            }
        }
        img {
            max-width: 100%;
            transition: all .5s;
            @include md {
                &:hover {
                    transform: scale(1.05);
                }
            }
        }
    }
}

.home-news {
    margin: auto;
    margin-top: 40px;
    padding: 0 20px;
    max-width: 1100px;
    @include md {
        margin-top: 100px;
    }
    .stitle {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        margin-bottom: 30px;
        @include md {
            margin-bottom: 50px;
        }
        .en {
            margin-bottom: 10px;
            font-size: 1.5rem;
            @include md {
                margin-bottom: 20px;
            }
        }
        .ja {
            font-size: 2.2rem;
            letter-spacing: .2em;
        }
    }
    .wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 110px;
        @include md {
            flex-direction: row;
            justify-content: flex-start;
        }
        > div {
            flex: 0 0 auto;
            position: relative;
            padding-left: 20px;
            width: 100%;
            border-left: 5px solid transparent;
            @include md {
                padding-left: 50px;
                width: calc(50% - 50px);
            }
            h3 {
                margin-bottom: 20px;
                font-size: 1.4rem;
            }
            ul {
                li {
                    display: flex;

                    padding: 1.5em 0;
                    font-size: 1.3rem;
                    border-top: 1px solid #000;
                    &:last-child {
                        border-bottom: 1px solid #000;
                    }
                    .date {
                        flex: 0 0 auto;
                        color: #4c4948;
                        width: 100px;
                    }
                    .title {
                        a {
                            color: #4c4948;
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
            .more {
                position: absolute;
                left: 0;
                bottom: -25px;
                width: 100%;
                height: 0;
                @include md {
                    bottom: -50px;
                }
                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    margin: auto;
                    color: #fff;
                    width: 290px;
                    height: 60px;
                    font-size: 1.5rem;
                    background-color: #000;
                    @include md {
                        width: 250px;
                    }
                }
            }
        }
        .left {
            margin-bottom: 120px;
            border-color: $color-red;
            @include md {
                margin-bottom: 0;
                margin-right: 55px;
            }
            h3 {
                color: $color-red;
            }
            .more {
                a {
                    background-color: $color-red;
                }
            }
        }
        .right {
            border-color: $color-green;
            h3 {
                color: $color-green;
            }
            .more {
                a {
                    background-color: $color-green;
                }
            }
        }
    }
}


.home-recruit {
    margin: auto;
    margin-top: 40px;
    padding: 0 20px;
    max-width: 1100px;
    @include md {
        margin-top: 100px;
    }
    .stitle {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        margin-bottom: 30px;
        @include md {
            margin-bottom: 50px;
        }
        .en {
            margin-bottom: 10px;
            font-size: 1.5rem;
            @include md {
                margin-bottom: 20px;
            }
        }
        .ja {
            font-size: 2.2rem;
            letter-spacing: .2em;
            text-align: center;
        }
    }
    .wrapper {
        .images {
            display: grid;
            column-gap: 10px;
            row-gap: 10px;
            a {
                display: block;
                width: 100%;
                overflow: hidden;
                @include md {
                    &:nth-child(1) {
                        grid-column-start: 1;
                        grid-column-end: 2;
                        grid-row-start: 1;
                        grid-row-end: 2;
                    }
                    &:nth-child(2) {
                        grid-column-start: 1;
                        grid-column-end: 2;
                        grid-row-start: 2;
                        grid-row-end: 3;
                    }
                    &:nth-child(3) {
                        grid-column-start: 2;
                        grid-column-end: 3;
                        grid-row-start: 1;
                        grid-row-end: 3;
                    }
                }
            }
            img {
                width: 100%;
                transition: all .5s;
                @include md {
                    &:hover {
                        transform: scale(1.05);
                    }
                }
            }
        }
    }
    ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        row-gap: 10px;

        margin: auto;
        margin-top: 20px;
        @include md {
            flex-direction: row;
            flex-wrap: wrap;
            column-gap: 20px;
            row-gap: 20px;

            margin-top: 50px;
        }
        li {
            width: 100%;
            @include md {
                width: auto;
            }
            a {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;

                color: #fff;
                width: 100%;
                height: 90px;
                font-size: 1.8rem;
                // background-image: url(/img/home/pattern.png);
                // background-color: #4C4948;
                background: repeating-linear-gradient(
                    135deg,
                    #4c4948,
                    #7f7f7f 2px,
                    #4c4948 2px,
                    #4c4948 6px,
                );
                @include md {
                    width: 225px;
                    font-size: 2rem;
                }
                @include lg {
                    width: 300px;
                    font-size: 2.4rem;
                }
                &::after {
                    position: absolute;
                    content: "";
                    top: 50%;
                    right: 20px;
                    display: block;
                    border-right: 2px solid #fff;
                    border-bottom: 2px solid #fff;
                    width: 8px;
                    height: 8px;
                    transform: translate(0, -50%) rotate( -45deg );
                    @include md {
                        width: 10px;
                        height: 10px;
                        transform: translate(0, -50%) rotate( -45deg );
                    }
                }
            }
        }
    }
}


.home-about {
    margin: auto;
    margin-top: 40px;
    padding: 0 20px;
    max-width: 1100px;
    @include md {
        margin-top: 100px;
    }
    .stitle {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        margin-bottom: 30px;
        @include md {
            margin-bottom: 50px;
        }
        .en {
            margin-bottom: 10px;
            font-size: 1.5rem;
            @include md {
                margin-bottom: 20px;
            }
        }
        .ja {
            font-size: 2.2rem;
            letter-spacing: .2em;
        }
    }
    .wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @include md {
            margin-top: -50px;
        }
        > div {
            flex: 0 0 auto;
            position: relative;
            width: 100%;
            overflow: hidden;
            @include md {
                width: calc(50% - 5px);
            }
            h3 {
                margin-bottom: 20px;
                font-size: 1.4rem;
            }
            img {
                width: 100%;
                transition: all .5s;
                @include md {
                    &:hover {
                        transform: scale(1.05);
                    }
                }
            }
            &.top {
                margin-bottom: 10px;
                width: 100%;
            }
            &.left {
                margin-bottom: 10px;
                @include md {
                    margin-bottom: 0;
                    margin-right: 10px;
                }
            }
        }
    }
}


.home-no1 {
    margin: auto;
    margin-top: 50px;
    max-width: 1400px;
    @include md {
        margin-top: 100px;
    }
    .stitle {
        text-align: center;
        margin-bottom: 50px;
        color: $color-red;
        font-size: 1.8rem;
        @include md {
            font-size: 2.8rem;
        }
    }
    .image {
        width: 100%;
        img {
            width: 100%;
        }
    }
    .slider {
        position: relative;
        z-index: 0;
        padding: 0 40px;
        img {
            max-width: 100%;
        }
        .button {
            position: absolute;
            top: 50%;
            left: 0;
            z-index: 1;

            display: flex;
            justify-content: space-between;

            padding: 0 40px;
            width: 100%;
            height: 0;
            div {
                width: 30px;
                height: 30px;
                outline: none;
                cursor: pointer;
                &.swiper-button-left {
                    transform: translate(-50%, -50%);
                }
                &.swiper-button-right {
                    transform: translate(50%, -50%);
                }
            }
            img {
                width: 100%;
            }
        }
    }
}


.home-nav {
    margin: auto;
    margin-top: 50px;
    padding: 0 40px 50px;
    max-width: 1100px;
    @include md {
        margin-top: 100px;
        padding: 0 20px;
    }
    ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        @include md {
            flex-direction: row;
        }
        li {
            margin: 5px 0;
            @include md {
                &:not(:last-child) {
                    margin-right: 10px;
                }
            }
        }
    }
    img {
        max-width: 100%;
    }
}