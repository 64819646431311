
#footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding-top: 50px;
    padding-bottom: 40px;
    background-color: rgba($color: #4c4948, $alpha: .1);
    @include md {
        flex-direction: row;
        flex-wrap: wrap;

        background: none;
    }
    .logo {
        width: 160px;
        @include md {
            margin-right: 30px;
        }
        img {
            max-width: 100%;
        }
    }
    .corporate {
        margin-top: 50px;
        @include md {
            margin-top: 0;
        }
        a {
            padding: .5em 1em;
            color: #fff;
            font-size: 1.4rem;
            background-color: $color-red;
            border-radius: 50px;
        }
    }
    .copyright {
        padding: 30px 0;
        width: 100%;
        text-align: center;
    }
}