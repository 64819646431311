
.career-tbanner {
    display: flex;
    justify-content: center;
    align-items: center;

    color: #fff;
    height: 60px;
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
    background: url('/img/common/dot.png');
    background-size: 5%;
    @include md {
        height: 180px;
        font-size: 4.6rem;
        background-size: auto;
    }
}


.career-description {
    margin: 50px auto;
    padding: 0 20px;
    width: 100%;
    max-width: 1200px;
    @include md {
        margin: 100px auto;
    }
    h2 {
        color: $color-red;
        font-size: 1.6rem;
        text-align: center;
        line-height: 1.8;
        @include md {
            font-size: 3.2rem;
        }
    }
    h3 {
        font-size: 2rem;
        text-align: center;
        line-height: 1.8;
        @include md {
            font-size: 3.2rem;
        }
    }
    p {
        margin-top: 2em;
        font-size: 1.4rem;
        line-height: 2;
        @include md {
            font-size: 1.7rem;
            text-align: center;
        }
    }
    ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        row-gap: 10px;

        margin: auto;
        margin-top: 20px;
        max-width: 800px;
        @include md {
            flex-direction: row;
            flex-wrap: wrap;
            column-gap: 20px;
            row-gap: 20px;

            margin-top: 50px;
            padding: 0 20px;
        }
        li {
            width: 100%;
            @include md {
                width: auto;
            }
            a {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;

                color: #fff;
                width: 100%;
                height: 60px;
                font-size: 1.5rem;
                background-color: #4C4948;
                @include md {
                    align-items: flex-start;
                    padding-top: 10px;
                    width: 200px;
                }
                @include lg {
                    width: 240px;
                }
                &::after {
                    position: absolute;
                    content: "";
                    top: 50%;
                    right: 20px;
                    display: block;
                    border-right: 2px solid #fff;
                    border-bottom: 2px solid #fff;
                    width: 8px;
                    height: 8px;
                    transform: translate(0, -50%) rotate( -45deg );
                    @include md {
                        left: 50%;
                        right: initial;
                        transform: translate(-50%, 50%) rotate( 45deg );
                    }
                }
            }
        }
    }
}


.career-detail {
    position: relative;
    margin: 50px auto;
    padding: 0 20px;
    width: 100%;
    max-width: 1400px;
    @include xxl {
        margin: 150px auto;
        padding: 0 180px;
        &:nth-child(even) {
            .text {
                margin-left: 0;
                .point {
                    left: 0;
                    right: initial;
        
                    transform: translate(-20%, -75%);
                }
            }
            .image {
                left: initial;
                right: 0;
            }
        }
    }
    h3 {
        margin-bottom: 40px;
        color: $color-red;
        font-size: 2rem;
        text-align: center;
        @include md {
            margin-bottom: 30px;
            font-size: 3.2rem;
        }
    }
    .anchor {
        display: block;
        transform: translateY(-#{$header-height-sp + 20px});
        @include md {
            // transform: translateY(-20px);
            transform: translateY(-#{$header-height + 20px});
        }
    }
    .image {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 0;
        width: 35%;
        transform: translateY(-50%);

        display: none;
        @include xxl {
            display: block;
        }
        img {
            max-width: 100%;
        }
    }
    .text {
        position: relative;
        z-index: 0;
        
        margin: auto;
        padding: 20px 20px 0;
        max-width: 770px;
        border: 4px solid $color-red;
        @include md {
            padding: 70px 80px 20px;
        }
        @include xxl {
            margin: 0;
            margin-left: auto;
        }
        h4 {
            margin-bottom: 10px;
            color: $color-red;
            font-size: 1.6rem;
            @include md {
                font-size: 2.5rem;
            }
        }
        p {
            margin-bottom: 30px;
            font-size: 1.4rem;
            line-height: 2;
            &:last-child {
                margin-bottom: 0;
            }
            @include md {
                font-size: 1.5rem;
            }
        }
        .img {
            text-align: center;
            @include xxl {
                display: none;
            }
            img {
                width: 100%;
            }
        }
        .point {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;

            display: flex;
            justify-content: center;
            align-items: center;

            padding: 0.5em 1em;
            color: #fff;
            font-size: 1.2rem;
            text-align: center;
            line-height: 1.5;
            background-color: $color-red;

            transform: translate(-4px, -100%);
            @include md {
                left: initial;
                right: 0;

                padding: 0;
                width: 120px;
                height: 120px;
                font-size: 1.7rem;
                border-radius: 50%;

                transform: translate(20%, -75%);
            }
        }
    }
    .link {
        margin-top: 20px;
        text-align: center;
        @include md {
            margin-top: 50px;
        }
        a {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            
            margin: 20px 0;
            color: #fff;
            width: 100%;
            height: 60px;
            font-size: 1.6rem;
            font-weight: 700;
            background-color: $color-red;
            @include md {
                margin: 30px auto;
                width: 350px;
                font-size: 1.8rem;
            }

            &::after {
                position: absolute;
                content: "";
                right: .5em;
                top: 50%;
                display: block;
                border-right: 2px solid #fff;
                border-bottom: 2px solid #fff;
                width: 8px;
                height: 8px;
                transform: translate(-50%,-50%) rotate( -45deg );
            }
        }
    }
    &.long {
        @include xxl {
            padding: 0 200px;
        }
        .image {
            width: 25%;
        }
        .text {
            max-width: 846px;
            @include xxl {
                padding: 50px 60px 10px;
            }
        }
        &:nth-child(odd) {
            .image {
                left: 180px;
            }
            .text {
                @include xxl {
                    padding-left: 220px;
                }
            }
        }
        &:nth-child(even) {
            .image {
                right: 180px;
            }
            .text {
                @include xxl {
                    padding-right: 220px;
                }
            }
        }
    }
}