//

@mixin sm {
    @media (min-width: 576px) { @content; }
}
@mixin md {
    @media (min-width: 768px) { @content; }
}
@mixin under-md {
    @media (max-width: 767px) { @content; }
}
@mixin lg {
    @media (min-width: 992px) { @content; }
}
@mixin xl {
    @media (min-width: 1200px) { @content; }
}
@mixin xxl {
    @media (min-width: 1400px) { @content; }
}



@mixin pc-only() {
    @include under-md {
        display: none !important;
    }
}
@mixin sp-only() {
    @include md {
        display: none !important;
    }
}

@mixin ie() {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        @content;
    }
}


$font-family: 'Noto Sans JP', "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;

$base-font-size: 10px;
$base-content-width: 1400px;

$header-height: 90px;
$header-height-sp: 55px;

$header-nav-bottun-size: 55px;


$color-red: #E7380D;
$color-green: #009844;