
.welfare-tbanner {
    display: flex;
    justify-content: center;
    align-items: center;

    color: #fff;
    height: 60px;
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
    background: url('/img/common/dot.png');
    background-size: 5%;
    @include md {
        height: 180px;
        font-size: 4.6rem;
        background-size: auto;
    }
}

.welfare-section1 {
    margin: 50px auto;
    padding: 0 20px;
    max-width: 1400px;
    @include md {
        margin: 100px auto;
    }
    h2 {
        color: $color-red;
        font-size: 1.8rem;
        text-align: center;
        @include md {
            font-size: 3.2rem;
        }
    }
    p {
        margin-top: 20px;
        font-size: 1.5rem;
        text-align: center;
        @include md {
            font-size: 1.7rem;
        }
    }
    .image {
        margin-top: 20px;
        text-align: center;
        img {
            max-width: 100%;
        }
    }
}

.welfare-section2 {
    margin: 50px auto;
    padding: 0 20px;
    max-width: 1400px;
    @include md {
        margin: 100px auto;
    }
    h2 {
        color: $color-red;
        font-size: 1.8rem;
        text-align: center;
        @include md {
            font-size: 3.2rem;
        }
    }
    p {
        margin-top: 20px;
        font-size: 1.5rem;
        text-align: center;
        line-height: 1.8;
        @include md {
            font-size: 1.7rem;
        }
    }
    ul {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        @include md {
            margin-top: 50px;
        }
        li {
            margin: 10px 0;
            width: 100%;
            @include md {
                margin: 20px 0;
                width: calc(50% - 10px);
                &:first-child {
                    width: 100%;
                }
                &:nth-child(2) {
                    margin-right: 20px;
                }
            }
            p {
                text-align: left;
            }
        }
        img {
            width: 100%;
        }
    }
}


.welfare-section3 {
    margin: 50px auto;
    padding: 0 20px;
    max-width: 1400px;
    @include md {
        margin: 100px auto;
    }
    h2 {
        color: $color-red;
        font-size: 1.8rem;
        text-align: center;
        @include md {
            font-size: 3.2rem;
        }
    }
    p {
        margin-top: 20px;
        font-size: 1.5rem;
        text-align: center;
        @include md {
            font-size: 1.7rem;
        }
    }
    ul {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        @include md {
            margin-top: 50px;
        }
        li {
            margin-bottom: 10px;
            width: calc(50% - 5px);
            &:nth-child(odd) {
                margin-right: 10px;
            }
            @include md {
                margin: 20px 0;
                width: calc(50% - 10px);
                &:nth-child(odd) {
                    margin-right: 20px;
                }
            }
            p {
                text-align: left;
            }
        }
        img {
            width: 100%;
        }
    }
}