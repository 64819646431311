
#header {
    position: fixed;
    width: 100%;
    height: $header-height-sp;
    background-color: #fff;
    z-index: 900;
    @include md {
        // position: static;
        height: $header-height;
    }
    .wrapper {
        display: flex;
        // justify-content: space-between;
        align-items: center;

        margin: auto;
        max-width: $base-content-width;
        padding-left: 20px;
        height: 100%;
    }
    .logo {
        img {
            height: 30px;
            @include md {
                height: 40px;
            }
        }
    }
    .text {
        margin-left: auto;
        padding-right: 10px;
        color: #E7380D;
        font-size: 1.3rem;
        letter-spacing: .1em;
        @include lg {
            padding-right: 20px;
        }
    }
    .recruit {
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: 800;

        display: flex;
        width: 100%;
        height: 50px;
        &.drug {
            a {
                background-color: $color-red;
            }
        }
        &.pharmacy {
            a {
                background-color: $color-green;
            }
        }
        a {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            color: #fff;
            width: 50%;
            font-size: 1.4rem;
            font-weight: 700;
            @include md {
                font-size: 1.6rem;
            }
            &:first-child {
                border-right: 1px solid #fff;
            }
            &::after {
                position: absolute;
                content: "";
                right: .5em;
                top: 50%;
                display: block;
                border-right: 2px solid #fff;
                border-bottom: 2px solid #fff;
                width: 8px;
                height: 8px;
                transform: translate(-50%,-50%) rotate( -45deg );
            }
        }
        @include md {
            position: static;
            width: 34%;
            height: 100%;
            max-width: 600px;
            font-size: 1.8rem;
        }
        @include lg {
            width: 40%;
            margin-left: 30px;
        }
        @include xl {
            width: 50%;
        }
    }
    .menu {
        display: block;
        margin-left: auto;
        width: $header-height-sp;
        height: $header-height-sp;
        background-color: $color-red;
        .button {
            display: flex;
            flex-direction: column;
            padding: 10px 10px 0;
            color: #fff;
            text-align: center;
            cursor: pointer;
            .bar {
                position: relative;
                display: block;

                width: 100%;
                height: 18px;
                span {
                    position: absolute;
                    left: 0;

                    width: 100%;
                    height: 1px;
                    background-color: #fff;

                    transition: all .5s;
                    &:nth-child(1) {
                        top: 0;
                    }
                    &:nth-child(2) {
                        top: 50%;
                    }
                    &:nth-child(3) {
                        top: 100%;
                    }
                }
            }
            p {
                margin-top: 5px;
                font-size: 1rem;
                letter-spacing: 1px;
            }
        }
        .content {
            display: none;
        }
        &.active {
            .button {
                .bar {
                    span {
                        &:nth-child(1) {
                            top: 50%;
                            transform: rotate(-45deg);
                            transform-origin: center;
                        }
                        &:nth-child(2) {
                            transform: scale(.8);
                            opacity: 0;
                        }
                        &:nth-child(3) {
                            top: 50%;
                            transform: rotate(45deg);
                            transform-origin: center;
                        }
                    }
                }
            }
            .content {
                position: fixed;
                top: 0;
                left: 0;
                z-index: -1;

                display: block;

                padding-top: $header-height-sp;
                width: 100%;
                height: 100%;
                background-color: #fff;
                ul {
                    display: flex;
                    flex-direction: column;
                    padding: 0 10px;
                    li {
                        border-bottom: 1px solid #000;
                        a {
                            position: relative;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            color: #000;
                            width: 100%;
                            height: 50px;
                            font-size: 1.5rem;
                            &::after {
                                position: absolute;
                                content: "";
                                right: .5em;
                                top: 50%;
                                display: block;
                                border-right: 2px solid #000;
                                border-bottom: 2px solid #000;
                                width: 8px;
                                height: 8px;
                                transform: translate(-50%,-50%) rotate( -45deg );
                            }
                        }
                    }
                }
            }
        }
    }
}