
.outline-tbanner {
    display: flex;
    justify-content: center;
    align-items: center;

    color: #fff;
    height: 60px;
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
    background: url('/img/common/dot.png');
    background-size: 5%;
    @include md {
        height: 180px;
        font-size: 4.6rem;
        background-size: auto;
    }
}


.outline-banner {
    img {
        width: 100%;
    }
}


.outline-table {
    margin: 50px 0;
    padding: 0 10px;
    width: 100%;
    max-width: 820px;
    font-size: 1.7rem;
    line-height: 2.5;
    @include md {
        margin: 50px auto 100px;
    }
    div.scroll {
        width: 100%;
        overflow-x: auto;
    }
    table.main {
        width: 100%;
        border-collapse: collapse;
        &.r {
            margin-top: 15px;
        }
        th, td {
            padding: .5em .5em;
            font-size: 1.3rem;
            vertical-align: top;
            letter-spacing: -0.05em;
            @include md {
                padding: .5em 1.5em;
                font-size: 1.7rem;
                letter-spacing: 0;
            }
        }
        th {
            color: $color-green;
            font-weight: 400;
            text-align: justify;
            text-align-last: justify;
            overflow: hidden;
            white-space: nowrap;
            width: 30%;
            min-width: 100px;
            @include md {
                width: 180px;
            }
        }
        td {
            max-width: 100%;
        }
    }
    table.sub-01,
    table.sub-02 {
        width: 100%;
        white-space: nowrap;
        border-collapse: collapse;
        tr {
            &:first-child,
            &.border {
                td {
                    border-top: 1px solid #000;
                    border-bottom: 1px solid #000;
                }
            }
        }
        th, td {
            padding: .5em 1em;
            font-size: 1.3rem;
            vertical-align: top;
            letter-spacing: -0.05em;
            @include md {
                padding: .5em 1.5em;
                font-size: 1.7rem;
                letter-spacing: 0;
            }
        }
        td {
            text-align: right;
            &:first-child {
                width: 9em;
                @include md {
                    width: 10em;
                }
            }
            &.border-right {
                border-right: 1px dashed #000;
            }
            &.border-bottom {
                border-bottom: 1px solid #000;
            }
            &.border-bottom2 {
                border-bottom: 1px dashed #000;
            }
            &.width-adjust-01 {
                width: 5em;
                text-align: center;
            }
        }
    }
    .text-right {
        text-align: right;
    }
    .text-center {
        text-align: center !important;
    }
}

.outline-image {
    margin: 50px auto;
    padding: 0 20px;
    width: 100%;
    max-width: 1200px;
    ul {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        li {
            margin: 5px;
            width: calc(50% - 10px);
            @include md {
                margin: 0;
                width: 16%;
            }
            img {
                max-width: 100%;
            }
        }
    }
}

.outline-action {
    margin: 50px auto;
    padding: 0 20px;
    width: 100%;
    max-width: 1200px;
    @include md {
        margin: 100px auto;
    }

    .stitle {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        margin-bottom: 50px;
        color: $color-green;
        text-align: center;
        .en {
            margin-bottom: 10px;
            font-size: 1.5rem;
            @include md {
                margin-bottom: 20px;
            }
        }
        .ja {
            font-size: 2.2rem;
            letter-spacing: .2em;
        }
    }

    table {
        width: 100%;
        border-collapse: collapse;
        tr {
            &:not(:last-child) {
                border-bottom: 1px solid #000;
            }
        }
        th, td {
            padding: 1em 0;
            line-height: 1.8;
            
            display: block;
            font-size: 1.3rem;
            @include md {
                display: table-cell;
                font-size: 1.7rem;
            }
        }
        th {
            color: #009844;
            white-space: nowrap;
            @include md {
                width: 15em;
            }
        }
        td {
            &.green {
                color: #009844;
            }
        }
        hr {
            margin: 20px 0;
            height: 0;
            border: 0;
            border-bottom: 1px dashed #000;
        }
    }
}

.outline-history {
    margin: 50px auto;
    padding: 0 20px;
    width: 100%;
    max-width: 1200px;
    @include md {
        margin: 100px auto;
    }

    .stitle {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        margin-bottom: 50px;
        color: $color-green;
        .en {
            margin-bottom: 10px;
            font-size: 1.5rem;
            @include md {
                margin-bottom: 20px;
            }
        }
        .ja {
            font-size: 2.2rem;
            letter-spacing: .2em;
        }
    }
    .wrapper {
        @include md {
            display: flex;
        }
        ul {
            font-size: 1.4rem;
            @include md {
                padding-left: 4em;
                padding-right: 1em;
                width: 50%;
                font-size: 1.5rem;
            }
            &:first-child {
                margin-bottom: 3em;
                @include md {
                    margin-bottom: 0;
                    border-right: 1px solid $color-green;
                }
                @include under-md {
                    &.off {
                        li:nth-child(n + 2) {
                            display: none;
                        }
                    }
                }
            }
            &:last-child {
                @include under-md {
                    &.off {
                        display: none;
                    }
                }
            }
            li {
                display: flex;
                line-height: 1.6;
                &:not(:last-child) {
                    margin-bottom: 3em;
                }
                span {
                    flex: 0 0 auto;
                    color: $color-green;
                    width: 80px;
                    white-space: nowrap;
                    letter-spacing: 1px;
                }
                p {

                }
            }
        }
    }
    .more {
        position: relative;
        display: block;
        margin-top: 20px;
        width: 100%;
        height: 60px;
        background-color: #878787;
        &::after {
            position: absolute;
            content: "";
            top: 50%;
            left: 50%;
            display: block;
            border-right: 2px solid #fff;
            border-bottom: 2px solid #fff;
            width: 8px;
            height: 8px;
            transform: translate(-50%,-50%) rotate( 45deg );
        }
    }
}
